import './styles.scss';
import { Layout } from 'antd';
const { Footer} = Layout;

const MyFooter = () => (
    <Footer id='page-footer'>
        footer here
    </Footer>
)


export default MyFooter;